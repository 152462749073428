body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  padding: 20px;
}

.header {
  padding: 10px;
  background: #edf2f4;
  border-bottom: 1px solid #999;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}

/* Remove header of the calendar */
.rdrDateDisplayWrapper {
  display: none!important;
}

@media only screen and (max-width: 600px) {
  input, textarea {
    font-size: 16px!important;
  }

  .accordion {
    width: 95%;
  }
}

@media only screen and (min-width: 601px) {
  .accordion {
    width: 80%;
  }
}

.accordion {
  border: 1px solid black;
  border-radius: 3px;
  margin: auto;
}

.accordion__heading {
  margin: 10px 20px;
}

.accordion__button:after {
  float:left;
  content: '';
  height: 10px;
  width: 10px;
  border-bottom: 3px solid currentColor;
  border-right: 3px solid currentColor;
  border-radius: 3px;
  transform: rotate(-45deg);
  margin-top: 2px;
  margin-right: 10px;
}
  
.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(45deg);
}

.accordion__panel {
  border-top: 1px solid black;
}

.rdrCalendarWrapper {
  border-radius: 3px;
}

@media only screen and (min-width: 500px) {
  .xlCalendar .rdrMonth {
    width: 40em!important;
  }
}

@media only screen and (max-width: 700px) {
  .inputFilter {
    width: 175px !important;
  }

  .filterContainer {
    width: 370px !important;
  }
}
.react-date-picker__inputGroup__leadingZero {
  margin-left:1px;
}

.filterContainer {
  width: 620px;
  margin: auto;
}

.radio-button-control {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  border: 2px solid black !important;
  margin: 5px !important;
  border-radius: 3px !important;
}

.disabled-row {
  color: red;
}